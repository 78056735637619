/* ProtectedComponent.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 10px;
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 300px;
  }
  
  .input {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button {
    padding: 0.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .alert {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  